import Swiper, { Pagination, Navigation } from 'swiper';
import 'swiper/css';

// 	const productSwiper = document.querySelector('.products-swiper');
// 	const sectorsFilterSelect = document.querySelector(
// 		'.sectors-select-filter select'
// 	);
// 	const sectorFilterButtons = document.querySelectorAll(
// 		'.sectors-button-filter__button'
// 	);
// 	const activeSectorFilter = document.querySelector(
// 		'.sectors-button-filter__button--active'
// 	);

// 	function filterProductsBySector(filter) {
// 		let products = document.querySelectorAll(
// 			'.products-swiper .swiper-slide'
// 		);
// 		products.forEach((product, index) => {
// 			if (product.classList.contains(filter)) {
// 				product.style.display = 'flex';
// 			} else {
// 				product.style.display = 'none';
// 			}
// 		});
// 	}

// 	if (activeSectorFilter != null) {
// 		filterProductsBySector(activeSectorFilter.dataset.filter);
// 	}

// 	if (productSwiper) {
// 		const prodSlider = new Swiper(productSwiper, {
// 			modules: [Pagination, Navigation],
// 			spaceBetween: 20,
// 			slidesPerView: 1.1,
// 			pagination: {
// 				el: productSwiper.querySelector('.swiper-pagination'),
// 				clickable: true,
// 			},
// 			navigation: {
// 				nextEl: productSwiper.querySelector('.swiper-button-next'),
// 				prevEl: productSwiper.querySelector('.swiper-button-prev'),
// 			},
// 			breakpoints: {
// 				1160: {
// 					slidesPerView: 2.2,
// 					slidesPerGroup: 2,
// 				},
// 				1460: {
// 					slidesPerView: 2.6,
// 					slidesPerGroup: 3,
// 				},
// 			},
// 		});

// 		if (sectorFilterButtons) {
// 			sectorFilterButtons.forEach((sectorFilterButton) => {
// 				sectorFilterButton.addEventListener('click', (e) => {
// 					activeClassRemove();
// 					productSwiper.classList.add('ajaxing');
// 					sectorFilterButton.classList.add(
// 						'sectors-button-filter__button--active'
// 					);
// 					setTimeout(() => {
// 						productSwiper.classList.remove('ajaxing');
// 						filterProductsBySector(
// 							sectorFilterButton.dataset.filter
// 						);
// 						prodSlider.updateSlides();
// 					}, 1000);
// 				});
// 			});
// 		}

// 		if (sectorsFilterSelect) {
// 			sectorsFilterSelect.addEventListener('change', (e) => {
// 				productSwiper.classList.add('ajaxing');
// 				setTimeout(() => {
// 					productSwiper.classList.remove('ajaxing');
// 					filterProductsBySector(sectorsFilterSelect.value);
// 					prodSlider.updateSlides();
// 				}, 500);
// 			});
// 		}

// 		function activeClassRemove() {
// 			sectorFilterButtons.forEach((sectorFilterButton) => {
// 				sectorFilterButton.classList.remove(
// 					'sectors-button-filter__button--active'
// 				);
// 			});
// 		}
// 	}
window.addEventListener('load', (e) => {
	const productSwiperBlocks = document.querySelectorAll(
		'.products-slider-block'
	);

	if (productSwiperBlocks) {
		productSwiperBlocks.forEach((productSwiperBlock) => {
			const sectorsFilterSelect = productSwiperBlock.querySelector(
				'.sectors-select-filter select'
			);
			const sectorFilterButtons = productSwiperBlock.querySelectorAll(
				'.sectors-button-filter__button'
			);
			const activeSectorFilter = productSwiperBlock.querySelector(
				'.sectors-button-filter__button--active'
			);

			function filterProductsBySector(filter) {
				let products = productSwiperBlock.querySelectorAll(
					'.products-swiper .swiper-slide'
				);
				products.forEach((product, index) => {
					if (product.classList.contains(filter)) {
						product.style.display = 'flex';
					} else {
						product.style.display = 'none';
					}
				});
			}

			if (activeSectorFilter != null) {
				filterProductsBySector(activeSectorFilter.dataset.filter);
			}

			const productSwipers =
				productSwiperBlock.querySelectorAll('.products-swiper');

			productSwipers.forEach((productSwiper) => {
				let prodSlider = new Swiper(productSwiper, {
					modules: [Pagination, Navigation],
					spaceBetween: 20,
					slidesPerView: 1.1,
					pagination: {
						el: productSwiper.querySelector('.swiper-pagination'),
						clickable: true,
					},
					navigation: {
						nextEl: productSwiper.querySelector(
							'.swiper-button-next'
						),
						prevEl: productSwiper.querySelector(
							'.swiper-button-prev'
						),
					},
					breakpoints: {
						1160: {
							slidesPerView: 2.2,
							slidesPerGroup: 2,
						},
						1460: {
							slidesPerView: 2.6,
							slidesPerGroup: 3,
						},
					},
				});
			});

			if (sectorFilterButtons) {
				sectorFilterButtons.forEach((sectorFilterButton) => {
					sectorFilterButton.addEventListener('click', (e) => {
						activeClassRemove();
						productSwiper.classList.add('ajaxing');
						sectorFilterButton.classList.add(
							'sectors-button-filter__button--active'
						);
						setTimeout(() => {
							productSwiper.classList.remove('ajaxing');
							filterProductsBySector(
								sectorFilterButton.dataset.filter
							);
							prodSlider.updateSlides();
						}, 1000);
					});
				});
			}

			if (sectorsFilterSelect) {
				sectorsFilterSelect.addEventListener('change', (e) => {
					productSwiper.classList.add('ajaxing');
					setTimeout(() => {
						productSwiper.classList.remove('ajaxing');
						filterProductsBySector(sectorsFilterSelect.value);
						prodSlider.updateSlides();
					}, 500);
				});
			}

			function activeClassRemove() {
				sectorFilterButtons.forEach((sectorFilterButton) => {
					sectorFilterButton.classList.remove(
						'sectors-button-filter__button--active'
					);
				});
			}
		});
	}
});
